<template>
  <div class="flex align-center flex-col justify-center my-4 w-full">
    <div class="container my-6 flex justify-end ">
      <div v-if="message" class="bg-gray-300 px-4 py-2 mr-4 rounded">
        {{ message._message }}
      </div>

      <AppButton
        :loading="loading"
        @clicked="save()"
        class="mr-2"
        color="bg-green-500 hover:bg-green-700 text-white"
      >
        Salvar página
      </AppButton>
    </div>

    <div class="flex container items-start">
      <div
        class="mr-6 w-3/4"
        v-if="languages.length > 0 && langs && Object.keys(langs).length > 0"
      >
        <nav class="flex mb-6">
          <div
            v-for="language in languages"
            :key="`${language.abbreviation}-nav`"
            @click.prevent="active = language.abbreviation"
            class="px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
            :class="{
              'bg-gray-100 border-gray-700 text-gray-700':
                active == language.abbreviation,
              'bg-gray-300': active != language.abbreviation
            }"
          >
            {{ language.title }}
          </div>
        </nav>

        <div
          v-for="language in languages"
          :key="language.abbreviation"
          :class="{ hidden: active != language.abbreviation }"
        >
          <label class="block mb-6 bg-white rounded shadow p-4 ">
            <span class="label">Título no menu [{{ language.title }}]</span>
            <input
              type="text"
              class="input"
              v-model="langs[language.abbreviation].title"
            />
          </label>
          <div class="bg-white rounded shadow p-4 mb-6">
            <TextEditor v-model="langs[language.abbreviation].content" />
          </div>
        </div>
      </div>

      <div v-else class="mr-6 w-3/4">
        <nav class="flex mb-6">
          <div
            class="bg-gray-300 px-4 py-2 rounded mr-4 text-gray-600 border border-gray-300 cursor-pointer"
          >
            Carregando...
          </div>
        </nav>
        <div class="bg-white rounded shadow p-4 mb-6"></div>
      </div>

      <div class="w-1/4 min-w-1/4 bg-white rounded shadow p-4">
        <label class="block mb-6">
          <span class="label">Título gerenciador</span>
          <input type="text" class="input" v-model="page.titleAdmin" />
          <p class="italic text-gray-500 text-sm ml-1 mt-1">
            Só aparece pra você.
          </p>
        </label>

        <!-- <label class="block mb-6">
          <span class="label">Página-mãe</span>
          <select
            v-model="page.parent"
            name="parent"
            id="parent"
            class="select"
          >
            <option value="">Sem página mãe</option>
            <option v-for="page in pages" :key="page._id" :value="page._id">{{
              page.titleAdmin
            }}</option>
          </select>
          <p class="italic text-gray-500 text-sm ml-1 mt-1">
            Página vai aparecer aninhada no menu.
          </p>
        </label> -->

        <label class="block mb-6">
          <span class="label">Tipo de Menu</span>
          <select v-model="page.type" name="type" id="type" class="select">
            <option value="">Selecione uma opção</option>
            <option value="content">Conteúdo (mais detaque)</option>
            <option value="tables"
              >Junto de tabelas (abaixo do conteúdo)</option
            >
            <option value="institutional"
              >Institucional (menor destaque)</option
            >
            <option value="home">Homepage (não usar)</option>
          </select>
          <p class="italic text-gray-500 text-sm ml-1 mt-1">
            Se nao selecionar nenhuma opção, o menu não aparecerá em lugar
            nenhum.
          </p>
        </label>

        <label class="block mb-6">
          <span class="label">Ordem no menu</span>
          <input type="text" class="input" v-model.number="page.order" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextEditor from "@/components/TextEditor/TextEditor";
import AppButton from "@/components/AppButton";

export default {
  name: "PagesForm",
  components: {
    TextEditor,
    AppButton
  },
  filters: {},
  props: {},
  data: function() {
    return {
      loading: false,
      active: null,
      message: null,

      page: {
        titleAdmin: "",
        order: 1,
        parent: "",
        langs: {},
        type: ""
      },

      langs: {}
    };
  },
  computed: {
    ...mapState({
      pages: state => state.Pages.list,
      languages: state => state.Settings.languages
    })
  },
  watch: {},
  async created() {},
  async mounted() {
    await this.$store.dispatch("Settings/list");

    if (this.pages.length == 0) {
      await this.$store.dispatch("Pages/list");
    }

    const param = this.$route.params.id;

    if (param == "adicionar") {
      if (this.languages.length > 0) {
        const langs = {};
        this.languages.forEach(item => {
          langs[item.abbreviation] = {
            title: "",
            content: ""
          };
        });

        this.active = this.languages[0].abbreviation;

        this.langs = langs;
      }
    } else {
      const page = await this.$store.dispatch("Pages/single", param);
      const langs = await this.$store.dispatch("Pages/langs", page._id);

      this.active = this.languages[0].abbreviation;

      if (page) {
        this.languages.forEach(item => {
          if (!langs[item.abbreviation]) {
            langs[item.abbreviation] = {
              title: "",
              content: ""
            };
          }
        });

        this.page = page;
        this.langs = langs;
      }
    }
  },
  methods: {
    async save() {
      this.loading = true;
      this.message = null;

      const param = this.$route.params.id;

      if (param == "adicionar") {
        const page = {
          titleAdmin: this.page.titleAdmin,
          order: this.page.order,
          parent: this.page.parent,
          date: Date.now(),
          langs: {}
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          page.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Pages/add", [page, langs]);
        if (response) {
          this.message = response;
        }
      } else {
        const page = {
          ...this.page
        };

        const langsArray = Object.keys(this.langs);
        for (const lang of langsArray) {
          page.langs[lang] = this.langs[lang].title;
        }

        const langs = {
          ...this.langs
        };

        const response = await this.$store.dispatch("Pages/update", [
          page,
          langs
        ]);
        if (response) {
          this.message = response;
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss"></style>
